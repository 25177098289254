<template>
  <div class="agreement">
    <div>
      <a-page-header
        title="协议签署记录"
        class="hearStyle"
      />
      <a-form layout="inline">
        <a-form-item label="适用项目：">
          <a-select
            style="width: 200px"
            placeholder="请选择"
            v-model="searchForm.projectId"
          >
            <a-select-option v-for="item in projects" :key="item.id" :value="item.id">
              {{ item.project_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label='签署项目'>
          <a-select
            style="width: 200px"
            placeholder="请选择"
            v-model="searchForm.signProjectId"
          >
            <a-select-option v-for="item in projects" :key="item.id" :value="item.id">
              {{ item.project_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="用户名：">
          <a-input v-model.trim="searchForm.username" placeholder="请输入用户名" class="inputClass" />
        </a-form-item>
        <a-form-item label="用户手机号：">
          <a-input v-model.trim="searchForm.phone" placeholder="请输入用户手机号" class="inputClass" />
        </a-form-item>
        <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
      </a-form>
      <a-table
        :columns="columns"
        :pagination="pagination.total ? pagination : false"
        @change="pageChange"
        style="margin-top: 10px"
        :data-source="tableData"
        bordered
      >
        <template slot="operation" slot-scope="text, record">
          <a href="javascript:;" @click="view(record)">查看</a>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { projectName } from '@/api/modularManagement'
import { getAgreementSigningRecord } from '@/api/agreement'
import { runDefaultMountEffects } from "qiankun";
export default {
  data() {
    return {
      tableData: [],
      projects: [],
      searchForm: {
        projectId: '',
        signProjectId: '',
        username: '',
        phone: ''
      },
      //分页数据
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
      page: 1,
      size: 10,
      columns: [
        { title: "协议ID", dataIndex: "agreementId", key: "agreementId", align: "center", },
        { title: "协议名称", dataIndex: "agreementName", key: "agreementName", align: "center" },
        {
          title: "协议类型", dataIndex: "agreementType", key: "agreementType", align: "center",
          customRender: (text) => {
            if (text === '1') {
              return '通勤巴士用户协议'
            } else if (text === '2') {
              return '积分支付用户协议'
            } else {
              return 'App用户协议'
            }
          }
        },
        { title: "适用项目", dataIndex: "projectIds", key: "projectIds", width: '400px', align: "center" },
        { title: "用户名", dataIndex: "userRealName", align: "center" },
        { title: "用户手机号", dataIndex: "userPhone", key: "userPhone", align: "center" },
        {
          title: "签署时间", dataIndex: "gmtSigned", align: "center",
          customRender: (text) => {
            if (text) {
              return moment(text).format('YYYY-MM-DD HH:mm:ss')
            } else {
              return '-'
            }
          }
        },
        { title: "签署项目", dataIndex: "projectId", align: "center" },
        {
          title: "签署状态", dataIndex: "status", align: "center",
          customRender: (text) => {
            if (text) {
              return '已签署'
            } else {
              return '已取消'
            }
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
    };
  },
  mounted() {
    this.getProjects()
    this.getList();
  },
  methods: {
    async getList() {
      const params = {
        ...this.searchForm,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize
      }
      const res = await getAgreementSigningRecord(params)
      const { data: {list, total}, code } = res
      if (code === '200') {
        this.tableData = list
        this.pagination.total = total
      }
    },
    // 重置
    async resetSearchForm() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.searchForm.projectId = ''
      this.searchForm.signProjectId = ''
      this.searchForm.username = ''
      this.searchForm.phone = ''
      await this.getList()
    },
    //分页操作
    pageChange(option) {
      this.pagination.current = option.current;
      this.pagination.pageSize = option.pageSize;
      this.getList();
    },
    async getProjects() {
      const res = await projectName()
      const { code, data: {items} } = res
      if (code === '200') {
        this.projects = items
      }
    },
    search() {
      this.pagination.current = 1
      this.getList()
    },
    view(row) {
      this.$router.push({
        path: "/agreement/agreementSigningRecord/detail",
        query: { id: row.id },
      });
    },
  },
};
</script>
<style scoped>
.action a {
    color: #1890ff;
    margin-right: 15px;
}
.action a:hover {
    color: #40a9ff;
}
.hearStyle {
    padding-left: 0;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(235, 237, 240);
}
</style>
