import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"

// 签署记录列表
export const getAgreementSigningRecord = (data) => {
  return fetchApi(URL.GET_AGREEMENT_SIGNING_RECORD, data, 'get', 'params')
}

// 签署记录详情
export const getAgreementSigningRecordDetail = (id) => {
  return fetchApi(URL.GET_AGREEMENT_SIGNING_RECORD_DETAIL + `/${id}`)
}
